import Lottie from 'lottie-react';
import LoadingAnimation from '../../assets/lottie/animation2.json';
import Navbar from '../../components/Navbar/index.jsx';
import theme from '../../styles';
import { NavLink } from 'react-router-dom';
import { styled } from '@mui/material';

function NoMatch() {
  return (
    <div
      style={{
        width: '100%',
        height: '100vh',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        gap: 20,
        backgroundColor: 'white',
      }}
    >
      <Navbar color={theme.color.primary} />

      <Lottie
        animationData={LoadingAnimation}
        loop
        style={{ maxWidth: '200px', margin: 50 }}
      />
      <H1>Ups... el sitio al que tratas de acceder no existe</H1>
      <NavButtonStyled to='/home'>Volver a inicio</NavButtonStyled>
    </div>
  );
}

const NavButtonStyled = styled(NavLink)({
  color: theme.color.light,
  backgroundColor: theme.color.primary,
  display: 'flex',
  textDecoration: 'none',
  padding: '10px 20px',
  fontSize: '20px',
  borderRadius: '10px',
  transition: 'transform 0.3s ease-out',
  alignItems: 'center',
  justifyContent: 'center',
  fontFamily: theme.fontFamily.secondary,
  '&:hover': {
    transform: 'translateY(-10px)',
  },
});

const H1 = styled('h1')(() => ({
  color: 'black',
  fontFamily: theme.fontFamily.secondary,
  fontSize: 25,
  textAlign: 'center',
}));

export default NoMatch;
