import { styled } from '@mui/material';
import theme from '../../styles';

const Nosotros = (props) => {
  return (
    <MainContainer>
      <H2>NICDET</H2>
      <H1>Nosotros</H1>
      <Paragraph>
        NICDET es una institución privada que realiza investigación científica y
        tecnológica, con la participación de personal altamente calificado, el
        uso de tecnologías de frontera, la colaboración con instituciones
        nacionales y extranjeras, y la vinculación con los diferentes sectores
        de la sociedad.
      </Paragraph>
    </MainContainer>
  );
};

const MainContainer = styled('div')(() => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'flex-start',
  padding: '50px 100px',
  [`@media (max-width: 600px)`]: {
    padding: '50px 20px',
  },
}));
const H1 = styled('h1')(() => ({
  fontFamily: theme.fontFamily.tertiary,
  fontSize: 40,
  fontWeight: 'bold',
  color: theme.color.textPrimary,
}));
const H2 = styled('h2')(() => ({
  fontFamily: theme.fontFamily.tertiary,
  fontSize: 16,
  color: theme.color.textPrimary,
}));
const Paragraph = styled('p')(() => ({
  fontFamily: theme.fontFamily.tertiary,
  fontSize: 16,
  color: theme.color.textPrimary,
  textAlign: 'left',
}));

export default Nosotros;
