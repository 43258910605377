import React, { useEffect } from 'react';
import Navbar from '../../components/Navbar/index.jsx';
import { TermsAndConditionsContent } from './termsAndConditionsContent';
import FormattedContent from '../../components/FormattedContent';
import { styled } from '@mui/material';
import theme from '../../styles';

const TermsAndConditions = () => {
  useEffect(() => {
    document.title = 'Términos y condiciones - NICDET';
  }, []);

  return (
    <MainContainer>
      <Navbar color={theme.color.primary}/>
      <ContentContainer>      
        <h2>{TermsAndConditionsContent.Title}</h2>
        <FormattedContent content={TermsAndConditionsContent.Content} />
      </ContentContainer>
    </MainContainer>
  );
};

const MainContainer = styled('div')(() => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'flex-start',
}));

const ContentContainer = styled('div')(() => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'flex-start',
  padding: '110px 100px 50px 100px',
}));

export default TermsAndConditions;
