import React, { useState, useEffect } from 'react';
import logo from '../../assets/nicdet-logotype.svg';
import logoPrimaryColor from '../../assets/nicdet-logotype-primary-color.svg';
import { NavLink, useNavigate, useLocation } from 'react-router-dom';
import { useScroll } from '../ScrollContext';
import { styled } from '@mui/material';
import theme from '../../styles';
import { Drawer, ClickAwayListener } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faTimes } from '@fortawesome/free-solid-svg-icons';

const Navbar = (props) => {
    const { memberships } = useScroll();
    const [scrollPosition, setScrollPosition] = useState(0);
    const [menuOpen, setMenuOpen] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();

    const handleScroll = () => {
        setScrollPosition(window.scrollY);
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const scrollToSection = (sectionRef) => {
        window.scrollTo({
            top: sectionRef.current.offsetTop - 73,
            behavior: 'smooth',
        });
    };

    const handleNavClick = (url) => {
        if (location.pathname !== url) {
            navigate(url);
            window.scrollTo(0, 0);
        } else {
            window.scrollTo(0, 0);
        }
        setMenuOpen(false); // Close drawer after navigation
    };

    const toggleDrawer = () => {
        setMenuOpen((prevOpen) => !prevOpen);
    };

    return (
        <NavbarContainer scrollPosition={scrollPosition}>
            <LogoContainer>
                <NavLink to='/home'>
                    <img src={props.color === theme.color.primary ? logoPrimaryColor : logo} className='h-12 grid-logo' alt="Logo" />
                </NavLink>
            </LogoContainer>
            <LinksContainer>
                <NavLinkStyled
                    to='/home'
                    onClick={(e) => {
                        e.preventDefault();
                        handleNavClick('/home');
                    }}
                    props={props}
                >
                    Inicio
                </NavLinkStyled>
                <NavLinkStyled
                    to='/education'
                    onClick={(e) => {
                        e.preventDefault();
                        handleNavClick('/education');
                    }}
                    props={props}
                >
                    Educación
                </NavLinkStyled>
                <NavButtonStyled to='/contact' className="nav-link" props={props}>
                    Contacto
                </NavButtonStyled>
            </LinksContainer>
            <HamburgerButton onClick={toggleDrawer}>
                <FontAwesomeIcon icon={faBars} color={props.color === theme.color.primary ? theme.color.primary : theme.color.light } />
            </HamburgerButton>
            <Drawer open={menuOpen} anchor="top">
                <ClickAwayListener onClickAway={toggleDrawer}>
                    <AccordionContainer>
                        <HeaderContainer>
                            <NavLink to='/home'>
                                <LogoContainer>
                                    <NavLink to='/home'>
                                        <img src={logoPrimaryColor} className='h-12 grid-logo' alt="Logo" />
                                    </NavLink>
                                </LogoContainer>
                            </NavLink>
                            <CloseButton onClick={toggleDrawer}>
                                <FontAwesomeIcon icon={faTimes} size="xl" color={theme.color.primary} />
                            </CloseButton>
                        </HeaderContainer>
                        <NavLinkStyled to="/home" onClick={(e) => {
                            e.preventDefault();
                            handleNavClick('/home');
                        }}>Inicio</NavLinkStyled>
                        <NavLinkStyled to="/education" onClick={(e) => {
                            e.preventDefault();
                            handleNavClick('/education');
                        }}>Educación</NavLinkStyled>
                        <NavLinkStyled to="/contact" onClick={(e) => {
                            e.preventDefault();
                            handleNavClick('/contact');
                        }}>Contacto</NavLinkStyled>
                    </AccordionContainer>
                </ClickAwayListener>
            </Drawer>
        </NavbarContainer>
    );
};

// Styled Components
const NavbarContainer = styled('div')(({ scrollPosition }) => ({
    position: 'fixed',
    top: 0,
    zIndex: 50,
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '20px 100px',
    transition: 'background-color 0.3s ease, backdrop-filter 0.3s ease',
    backgroundColor: scrollPosition === 0 ? 'transparent' : 'rgba(148, 163, 184, 0.4)',
    backdropFilter: scrollPosition === 0 ? 'none' : 'blur(10px)',
    boxShadow: scrollPosition === 0 ? 'none' : '0 4px 6px rgba(0, 0, 0, 0.1)',
    fontFamily: theme.fontFamily.secondary,
    color: theme.color.light,
    '@media (max-width: 600px)': {
        padding: '30px'
    },
}));

const LogoContainer = styled('div')({
    flexShrink: 0,
    flexBasis: '180px',
});

const LinksContainer = styled('div')({
    display: 'flex',
    justifyContent: 'center',
    gap: '10px',
    [`@media (max-width: 768px)`]: {
        display: 'none', // Hide on smaller screens
    },
});

const NavLinkStyled = styled(NavLink)(({ props }) => ({
    color: props?.color || theme.color.light, // Use optional chaining to prevent undefined error
    textDecoration: 'none',
    padding: '10px 20px',
    fontSize: '20px',
    transition: 'transform 0.3s ease-out',
    '&:hover': {
        color: theme.color.accent,
        transform: 'translateY(-10px)',
    },
    '@media (max-width: 600px)': {
        color: theme.color.primary
    },
}));

const NavButtonStyled = styled(NavLink)({
    color: theme.color.light,
    backgroundColor: theme.color.primary,
    display: 'flex',
    textDecoration: 'none',
    padding: '10px 20px',
    fontSize: '20px',
    borderRadius: '10px',
    transition: 'transform 0.3s ease-out',
    alignItems: 'center',
    justifyContent: 'center',
    '&:hover': {
        transform: 'translateY(-10px)',
    },
});

const HamburgerButton = styled('button')({
    display: 'none',
    [`@media (max-width: 768px)`]: {
        display: 'block',
        backgroundColor: 'transparent',
        border: 'none',
        color: theme.color.light,
        fontSize: '24px',
        cursor: 'pointer',
    },
});

const AccordionContainer = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '20px',
    gap: '20px',
    backgroundColor: 'rgba(148, 163, 184, 0.4)',
    color: theme.color.light,
});

const CloseButton = styled('button')({
    display: 'flex',
    justifyContent: 'flex-end',
    width: '100%',
    padding: '10px',
    backgroundColor: 'transparent',
    border: 'none',
    color: theme.color.light,
    fontSize: '24px',
    cursor: 'pointer',
});

const HeaderContainer = styled('div')({
    width: '100%',
    display: 'flex',
    flexDirection: 'row'
})

const Image = styled('img')({

})

export default Navbar;