import React, { useEffect, useState } from 'react';
import { styled } from '@mui/material';
import theme from '../../styles';
import PrimaryButton from '../../components/PrimaryButton';
import SecondaryButton from '../../components/SecondaryButton';
import Lottie from 'lottie-react';
import LoadingAnimation from '../../assets/lottie/animation1.json';
import { getHeroSection } from '../../api/heroSection';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";

const HeroSection = () => {
  const [heroContent, setHeroContent] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchHeroContent = async () => {
      try {
        const heroData = await getHeroSection();
        setHeroContent(heroData.data[0]?.attributes);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching hero section:', error);
        setError('Failed to load hero content');
        setLoading(false);
      }
    };

    fetchHeroContent();
  }, []);

  if (loading) {
    return (
      <LoadingContainer>
        <Lottie animationData={LoadingAnimation} loop style={{ maxWidth: '200px', margin: 50 }} />
      </LoadingContainer>
    );
  }

  if (error) {
    return <div>{error}</div>;
  }

  const { title, mediaFromLink, cta } = heroContent || {};

  const settings = {
    dots: false,
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    swipe: false,
    autoplay: false,
    autoplaySpeed: 3000,
  };

  return (
    <Slides {...settings}>
      <SlideContainer>
        <MainContainer backgroundImage={mediaFromLink?.Link}>
          <H1>
            <Span>{title?.split(' ')[0]}</Span>
            <br />
            {title?.replace(title.split(' ')[0], '').trim()}
          </H1>
          <ButtonContainer>
            {cta[0]?.Link && (
              <PrimaryButton onClick={() => window.location.href = cta[0]?.Link}>
                {cta[0]?.Name || 'CONTACTO'}
              </PrimaryButton>
            )}
            {cta[1]?.Link && (
              <SecondaryButton onClick={() => window.location.href = cta[1]?.Link}>
                {cta[1]?.Name || 'SABER MÁS'}
              </SecondaryButton>
            )}
          </ButtonContainer>
        </MainContainer>
      </SlideContainer>
      <SlideContainer>
        <MainContainer backgroundImage={mediaFromLink?.Link}>
          <H1>
            <Span>{title?.split(' ')[0]}</Span>
            <br />
            {title?.replace(title.split(' ')[0], '').trim()}
          </H1>
          <ButtonContainer>
            {cta[0]?.Link && (
              <PrimaryButton onClick={() => window.location.href = cta[0]?.Link}>
                {cta[0]?.Name || 'CONTACTO'}
              </PrimaryButton>
            )}
            {cta[1]?.Link && (
              <SecondaryButton onClick={() => window.location.href = cta[1]?.Link}>
                {cta[1]?.Name || 'SABER MÁS'}
              </SecondaryButton>
            )}
          </ButtonContainer>
        </MainContainer>
      </SlideContainer>
    </Slides>
  );
};

// Styled Components
const LoadingContainer = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '100vh',
});

const MainContainer = styled('div')(({ backgroundImage }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-end',
  alignItems: 'flex-start',
  position: 'relative',
  width: '100%',
  height: '100vh',
  backgroundImage: `url(${backgroundImage})`,
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  padding: 100,
  gap: 50,
  '&::before': {
    content: '""',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    zIndex: 1,
  },
  '& > *': {
    position: 'relative',
    zIndex: 2,
  },
  [`@media (max-width: 768px)`]: {
    height: '60vh',
    gap: '35px',
    padding: '10px',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));

const H1 = styled('h1')({
  color: 'white',
  fontFamily: theme.fontFamily.primary,
  fontWeight: 'bold',
  fontSize: '54px',
  textAlign: 'left',
  [`@media (max-width: 768px)`]: {
    fontSize: '32px',
    textAlign: 'center',
  },
});

const Span = styled('span')({
  color: theme.color.babyBlue,
});

const ButtonContainer = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  gap: '20px',
  [`@media (max-width: 768px)`]: {
    flexDirection: 'column',
    gap: '10px',
  },
});

const Slides = styled(Slider)({
  width: '100%',
  height: '100%',
});

const SlideContainer = styled('div')({
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
});

export default HeroSection;