import { styled } from '@mui/material';
import theme from '../../styles';
import { useScroll } from '../../components/ScrollContext';

const EducationSpecializedTraining = (props) => {
  const { specializedTraining } = useScroll();

  return (
    <MainContainer ref={specializedTraining}>
      <H1>Capacitación especializada</H1>
      <Paragraph>
        Enfocado a profesionales que buscan actualizar o ampliar sus habilidades
        para avanzar en sus carreras o adaptarse a cambios en sus industrias,
        trabajadores que buscan desarrollar habilidades adicionales para mejorar
        su desempeño en el trabjo y avanzar en sus roles dentro de la
        organización, para las empresas que requieran una capacitación basica,
        re-entrenamiento de fuerza laboral y una adaptación a nuevas
        tecnologías.
      </Paragraph>
    </MainContainer>
  );
};

const MainContainer = styled('div')(() => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'flex-start',
  padding: '50px 100px',
  gap: 20,
  [`@media (max-width: 600px)`]: {
    padding: '20px',
  },
}));
const H1 = styled('h1')(() => ({
  fontFamily: theme.fontFamily.tertiary,
  fontSize: 40,
  fontWeight: 'bold',
  color: theme.color.textPrimary,
}));
const Paragraph = styled('p')(() => ({
  fontFamily: theme.fontFamily.tertiary,
  fontSize: 16,
  color: theme.color.textPrimary,
  textAlign: 'left',
}));

export default EducationSpecializedTraining;
