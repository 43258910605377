import React from 'react';
import Navbar from '../../components/Navbar/index.jsx';
import { PrivacyNoticeContent } from './privacyNoticeContent'
import FormattedContent from '../../components/FormattedContent';
import { styled } from '@mui/material';
import theme from '../../styles';

const PrivacyNotice = () => {
  return (
    <MainContainer>
      <Navbar color={theme.color.primary}/>
      <ContentContainer>
        <h2>{PrivacyNoticeContent.Title}</h2>
        <FormattedContent content={PrivacyNoticeContent.Content} />
      </ContentContainer>
    </MainContainer>
  );
};

const MainContainer = styled('div')(() => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'flex-start',
}));

const ContentContainer = styled('div')(() => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'flex-start',
  padding: '110px 100px 50px 100px',
}));

export default PrivacyNotice;